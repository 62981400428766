<template>
    <v-dialog v-model="dialog" persistent width="500">

        <v-card>
            <v-card-title>
                {{ title }}
            </v-card-title>

            <v-card-text>
                {{ body }} <strong>{{ name }}</strong>
            </v-card-text>

            <v-card-actions>

                <v-btn color="error" @click="confirm(false)">
                    <v-icon left> mdi-close</v-icon>
                    {{ cancelText }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="confirm(true)">
                    <v-icon left> mdi-check</v-icon>
                    {{ confirmText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        title: {
            type: String,
            require: true,
            default: "",
        },
        body: {
            type: String,
            require: true,
            default: "",
        },
        name: {
            type: String,
            require: true,
            default: "",
        },
        dialog: {
            type: Boolean,
            require: true,
            default: false,
        },
        confirmText: {
            type: String,
            require: false,
            default: "Confirmar"
        },
        cancelText: {
            type: String,
            require: false,
            default: "Cancelar"
        }
    },

    data: () => ({}),
    methods: {
        confirm(status) {
            this.$emit('dialog-event', status)
        }
    }
};
</script>
